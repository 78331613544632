import { Controller } from "stimulus";
import identity from "../identity";
import * as Sentry from '@sentry/browser';

export default class extends Controller {
  static targets = ["name", "email", "password", "error", "button"];

  async submit(e) {
    e.preventDefault();

    this.buttonTarget.disabled = true;
    this.errorTarget.hidden = true;
    this.element.classList.remove('uk-animation-shake');

    try {
      await identity.signup(
        this.emailTarget.value,
        this.passwordTarget.value,
        { full_name: this.nameTarget.value }
      );
      await identity.login(this.emailTarget.value, this.passwordTarget.value, true);
      window.location = this.element.action;
    } catch (error) {
      this.buttonTarget.disabled = false;
      this.error(error)
    }
  }

  error(error) {
    this.errorTarget.textContent = error?.json.error_description || error.message || error.toString();
    this.errorTarget.hidden = false;
    this.element.classList.add('uk-animation-shake');
  }
}
