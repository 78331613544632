// https://stackoverflow.com/questions/21399872/how-to-detect-whether-html5-video-has-paused-for-buffering
export default class BufferDetector {
  lastPlayPos = 0;
  checkInterval = 1000;

  constructor(element, onBuffer = () => {}, onResume = () => {}) {
    this.element = element;
    this.onBuffer = onBuffer;
    this.onResume = onResume;
    this.reset();

    ['play', 'playing', 'seeked'].forEach(event => {
      this.element.addEventListener(event, () => this.start());
    });

    ['pause', 'seeking', 'ended'].forEach(event => {
      this.element.addEventListener(event, () => this.stop());
    });
  }

  reset() {
    this.buffering = false;
    this.count = 0;
    this.duration = 0;
  }

  start() {
    if(this.interval) return; // already watching

    this.interval = setInterval(() => this.check(), this.checkInterval);
  }

  stop() {
    clearInterval(this.interval);
    this.interval = null;
  }

  check() {
    // Skip check if paused
    if(this.element.paused) return

    const currentPlayPos = this.element.currentTime;

    // offset should be at most the check interval, but allow for some margin
    var offset = (this.checkInterval * 0.9) / 1000

    if(this.buffering) {
      // Check if playback has advanced
      if (currentPlayPos > (this.lastPlayPos + offset)) {
        this.onResume();
        this.buffering = false;
      } else {
        // Track how much time has been spent buffering
        this.duration += this.checkInterval;

        // Still buffering
        this.onBuffer();
      }
    } else {
      // Check if playback has stalled
      if (currentPlayPos < (this.lastPlayPos + offset)) {
        this.buffering = true;
        this.count++;
        this.onBuffer();
      }
    }

    this.lastPlayPos = currentPlayPos;
  }
}
