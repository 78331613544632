import { Controller } from "stimulus";
import * as Sentry from '@sentry/browser';
import BufferDetector from "../buffer-detector";

export default class extends Controller {
  static targets = ["hls"];

  connect() {
    // Disable right click on video
    this.element.addEventListener('contextmenu', (e) => e.preventDefault());
    this.element.addEventListener('error', (e) => this.error(e));

    if (this.element.canPlayType('application/x-mpegURL; codecs=avc1.77.30,mp4a.40.2') === "probably") {
      this.watchRemote();
    } else {
      this.disableHls();
    }

    this.bufferDetector = new BufferDetector(this.element, () => this.buffering());

    `canplay canplaythrough complete durationchange emptied ended loadeddata loadedmetadata pause play playing ratechange seeked seeking stalled suspend volumechange waiting`.split(' ').forEach(event => {
      this.element.addEventListener(event, e => console.log(event));
    });
  }

  disconnect() {
    this.cancelWatchRemote();
  }

  buffering() {
    const data = {
      currentTime: this.element.currentTime,
      bufferCount: this.bufferDetector.count,
      bufferDuration: this.bufferDetector.duration,
    }
    console.log("buffering", data);
    if(this.bufferDetector.count >= 5 && this.bufferDetector.duration > 30 * 1000) {
      Sentry.setExtras()
      Sentry.setTag("currentSrc", this.element.currentSrc && this.element.currentSrc.split('?')[0]);
      Sentry.captureMessage('Excessive buffering');

      // No need to log again
      this.buffering = () => { };
    }
  }

  watchRemote() {
    if(!this.remote) return;

    this.remote.watchAvailability(available => {
      if(!available) return;

      // Disable HLS stream if connecting to remote playback device
      this.remote.onconnecting = this.remote.onconnect = () => this.disableHls();

      // Safari doesn't always trigger events, so watch for connect
      this.interval = setInterval(() => {
        if(this.remote.state != 'disconnected') this.disableHls();
      }, 1000);
    }).catch((error) => {
      Sentry.captureException(error);
      console.error('Remote playback error', error);
    });
  }

  cancelWatchRemote() {
    this.remote?.cancelWatchAvailability();
    clearInterval(this.interval);
  }

  disableHls() {
    if(!this.hasHlsTarget) return;

    console.log("Disabling HLS source");
    this.hlsTarget.remove();
    this.element.load();
    this.cancelWatchRemote();
  }

  error(e) {
    // Report everything except abort
    if(this.element.error && this.element.error.code !== this.element.error.MEDIA_ERR_ABORTED) {
      Sentry.captureEvent(e);
    }

    console.error("Video playback error", this.element.error);
  }

  get remote() {
    return this.element.remote;
  }
}
