import { Controller } from "stimulus";
import identity from '../identity';

export default class extends Controller {
  async initialize() {
    const res = await fetch('/.netlify/functions/commento-sso' + window.location.search, {
      headers: { Authorization: `Bearer ${await this.jwt()}`}
    });
    const { payload, hmac } = await res.json();
    window.location = `https://commento.io/api/oauth/sso/callback?payload=${payload}&hmac=${hmac}`
  }

  // Get JWT for current user
  async jwt() {
    return identity.currentUser().jwt();
  }
}