import "./redirects";

// Standalone runtime for Regenerator-compiled generator and async functions.
import "regenerator-runtime/runtime";

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if(process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: 'https://489862a48deb4ac3b5a003d5c802a071@o490508.ingest.sentry.io/5554543',
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);

import { Application } from "stimulus"
const controllers = require("./controllers/*.js");

const application = Application.start()
for(const key in controllers) {
  const identifier = key.replace(/_controller$/, '').replace(/_/g, '-');
  application.register(identifier, controllers[key].default)
}
