import { Controller } from "stimulus";
import identity from "../identity";
import * as Sentry from '@sentry/browser';

export default class extends Controller {
  static targets = ["email", "success", "error", "button"];

  async submit(e) {
    e.preventDefault();

    this.buttonTarget.disabled = true;
    this.errorTarget.hidden = true;
    this.element.classList.remove('uk-animation-shake');

    try {
      const response = await identity.requestPasswordRecovery(this.emailTarget.value);
      this.success();
    } catch (error) {
      this.buttonTarget.disabled = false;
      this.error(error)
    }
  }

  success() {
    this.successTarget.hidden = false;
  }

  error(error) {
    this.errorTarget.textContent = error?.json.error_description || error.message || error.toString();
    this.errorTarget.hidden = false;
    this.element.classList.add('uk-animation-shake');
  }
}
