import { Controller } from "stimulus";
import identity from '../identity';
import * as Sentry from '@sentry/browser';

export default class extends Controller {
  static targets = ["source"];

  connect() {
    this.refresh();
  }

  async refresh() {
    const urls = this.sourceTargets.map(target => target.dataset.src);
    console.log("signing urls", urls)

    // Make request to set cookies
    const res = await fetch('/.netlify/functions/authenticate-stream', {
      method: 'POST',
      headers: { Authorization: `Bearer ${await this.jwt()}`},
      body: JSON.stringify(urls)
    });

    if(!res.ok) {
      Sentry.captureMessage(`Failed to authenticate stream: ${res.status} ${res.statusText}`);
    } else {
      this.setSrc(await res.json());
      this.watch();
    }
  }

  setSrc(urls) {
    for(const url in urls) {
      const source = this.sourceTargets.find(e => e.dataset.src == url)
      source?.setAttribute('src', urls[url]);
    }
    this.element.load();
  }

  watch() {
    setTimeout(() => this.refresh(), this.ttl);
    console.log(`Stream authentication expires in ${this.ttl}`);
  }

  get ttl() {
    const expiration = this.expiration;
    if(!expiration) return 0;

    const currentTime = new Date().getTime();
    return Date.parse(expiration) - currentTime;
  }

  get expiration() {
    return document.cookie.split('; ').
      find(row => row.startsWith('stream-expires'))?.
      split('=')[1];
  }

  // Get JWT for current user
  async jwt() {
    return identity.currentUser().jwt();
  }
}
