import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["amazon", "amazonVideo", "vimeo", "vimeoVideo"];

    switchVideo(e){
        const currentAWS = e.target.classList.contains('aws');
        if(currentAWS){
            e.target.classList.remove('aws');
            e.target.classList.add('vimeo');
            this.vimeoTarget.classList.remove('uk-hidden');
            this.amazonTarget.classList.add('uk-hidden');
            this.amazonVideoTarget.pause();
        } else {
            e.target.classList.remove('vimeo');
            e.target.classList.add('aws');
            this.vimeoTarget.classList.add('uk-hidden');
            this.amazonTarget.classList.remove('uk-hidden');
            this.vimeoVideoTarget.contentWindow.postMessage('{"method":"pause"}', '*');
        }
    }
}