import { Controller } from "stimulus";
import identity from '../identity';

export default class extends Controller {
  static targets = ["name", "email", "ua"];

  connect() {
    this.nameTarget.value = identity.currentUser().user_metadata.full_name;
    this.emailTarget.value = identity.currentUser().email;
    this.uaTarget.value = navigator.userAgent;
  }
}
