import { Controller } from "stimulus";
import identity from "../identity";
import * as Sentry from '@sentry/browser';

export default class extends Controller {
  async initialize() {
    ['login', 'signup', 'logout'].forEach(action => {
      if(window.location.hash == `#${action}`) {
        return this[action]();
      }
    });

    // Check if user is currently signed in
    if(this.user) {
      await this.refresh();
      this.loggedIn();
    }
  }

  // Refresh jwt token
  async refresh(force = false) {
    await this.user.jwt(force);
    console.log("Identity expires", this.ttl);
    setTimeout(() => this.refresh(true), this.ttl);
  }

  loggedIn() {
    console.log('Logged in', this.user)

    if(window.location.pathname == '/') {
      return window.location = '/watch'
    }

    // Set user information
    Sentry.configureScope(scope => {
      scope.setUser({ id: this.user.id, email: this.user.email });
    });

    document.body.classList.add('logged-in');
  }

  login() {
    return window.location = `/login`
  }

  signup() {
    return window.location = `/signup`
  }

  async logout(event) {
    event?.preventDefault();
    await this.user?.logout();
    window.location = '/';
  }

  get user() {
    return identity.currentUser();
  }

  get ttl() {
    return +new Date(this.user.token.expires_at) - new Date()
  }
}
