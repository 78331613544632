import { Controller } from "stimulus";
import identity from "../identity";
import * as Sentry from '@sentry/browser';

export default class extends Controller {
  static targets = ["email", "password", "error", "button"];

  async initialize() {
    if(identity.currentUser()) {
      // User is already logged in, attempt to refresh the jwt
      await identity.currentUser().jwt(true);

      // Refresh user data to get new roles
      await identity.currentUser().getUserData();

      window.location = this.returnTo;
    }
  }

  async submit(e) {
    e.preventDefault();

    this.buttonTarget.disabled = true;
    this.errorTarget.hidden = true;
    this.element.classList.remove('uk-animation-shake');

    try {
      await identity.login(this.emailTarget.value, this.passwordTarget.value, true);
      window.location = this.returnTo;
    } catch (error) {
      this.buttonTarget.disabled = false;
      this.error(error)
    }
  }

  error(error) {
    this.errorTarget.textContent = error?.json.error_description || error.message || error.toString();
    this.errorTarget.hidden = false;
    this.element.classList.add('uk-animation-shake');
  }

  get returnTo() {
    return window.location.search.match(/^\?to=(\/.*)$/)?.pop() || this.element.action;
  }
}
