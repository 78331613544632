import identity from "./identity";

(() => {
  const roles = identity.currentUser()?.app_metadata?.roles || [];
  const location = window.location.toString().replace(window.origin, '');

  // Forward to password reset page
  if(location.match(/^\/#recovery_token/)) {
    return window.location = `/reset${window.location.hash}`
  }

  // Redirect signed in users to the /watch page
  if(['/', '/payment'].includes(location) && roles.includes('paid')) {
    return window.location = '/watch'
  }

  if(location == '/payment' && !roles.includes('user')) {
    return window.location = '/'
  }

  if(location.match(/^\/watch.*/)) {
    if(roles.includes('user')) {
      return window.location = '/payment'
    } else if (roles.length == 0) {
      return window.location = '/'
    }
  }
})();