import { Controller } from "stimulus";

// Save form values in sessionStorage, restore on load
export default class extends Controller {
  static targets = [ "input" ];

  initialize() {
    this.load();
  }

  // Load form values from sessionStorage
  load(event) {
    this.inputTargets.forEach(input => {
      input.value = sessionStorage.getItem(input.name) || input.value;
    });
  }

  // Save form values into sessionstorage
  save(event) {
    this.inputTargets.forEach(input => {
      sessionStorage.setItem(input.name, input.value);
    });
  }

  clear(event) {
    this.inputTargets.forEach(input => {
      sessionStorage.setItem(input.name, null);
    });

  }
}
