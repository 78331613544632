import { Controller } from "stimulus";
import identity from '../identity';

const unknownError = "We're having trouble with your payment. Please contact us at crew@80northseries.com";

const style = {
  base: {
    fontSize: "18px",
    fontWeight: "bold",
    "::placeholder": {
      fontWeight: "normal",
      fontSize: "16px"
    }
  },
};

export default class extends Controller {
  static targets = [ "card", "paymentRequest", "button", "amount", "error", "amountWarning" ]

  async initialize() {
    // Only run setup if element has `data-payment-setup`.
    if(this.data.has('setup')) {
      this.setup();
    }
  }

  setup() {
    this.stripe = Stripe(process.env.STRIPE_PUBLIC_KEY, {
      apiVersion: "2020-08-27",
    });

    this.update();
    this.setupCard();
    this.setupPaymentRequest();
  }

  setupCard() {
    if(this.card) return;

    console.log("Setting up credit card");
    const elements = this.stripe.elements();
    const card = elements.create("card", { style: style });
    card.mount(this.cardTarget);
    card.on("change", event => this.cardChange(event));

    this.card = card;
  }

  cardChange(event) {
    // Disable the Pay button if there are no card details in the Element
    this.disabled = !event.complete || !this.clientSecret;
    this.error(event.error?.message);
  }

  async setupPaymentRequest() {
    console.log("Setting up payment request", this.paymentRequestOptions)

    const paymentRequest = this.paymentRequest = this.stripe.paymentRequest({
      country: 'US', // can't be passed to update
      ...this.paymentRequestOptions
    });

    // Check the availability of the Payment Request API first.
    if(!await paymentRequest.canMakePayment()) {
      console.log("PaymentRequest not available")
      return;
    }

    const elements = this.stripe.elements();
    const button = elements.create('paymentRequestButton', { paymentRequest });

    this.paymentRequestTarget.hidden = false;
    button.mount(this.paymentRequestTarget);

    paymentRequest.on('paymentmethod', event => this.processPaymentRequest(event));
  }

  async processPaymentRequest(event) {
    // Confirm the PaymentIntent without handling potential next actions (yet).
    const {paymentIntent, error: confirmError} = await this.stripe.confirmCardPayment(
      this.clientSecret,
      {payment_method: event.paymentMethod.id},
      {handleActions: false}
    );

    if (confirmError) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      event.complete('fail');
    } else {
      // Report to the browser that the confirmation was successful, prompting
      // it to close the browser payment method collection interface.
      event.complete('success');
      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11" instead
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (paymentIntent.status === "requires_action") {
        // Let Stripe.js handle the rest of the payment flow.
        const { error } = await this.stripe.confirmCardPayment(this.clientSecret);
        if (error) {
          this.error(error.message || "We had trouble collecting your payment info")
        } else {
          this.verifyPayment(paymentIntent)
        }
      } else {
        this.verifyPayment(paymentIntent)
      }
    }
  }

  async update(e) {
    e?.preventDefault();

    if(!this.amount) {
      this.clientSecret = null;
      return;
    }

    console.log("Getting client secret");
    this.disabled = true;

    const res = await fetch('/.netlify/functions/payment-token', {
      method: "POST",
      headers: { Authorization: `Bearer ${await this.jwt()}`},
      body: JSON.stringify({ amount: this.amount })
    });

    const { success, clientSecret, message } = res.ok ? await res.json() : { success: false };

    if(success) {
      console.log("Client secret updated");
      this.clientSecret = clientSecret;
      this.disabled = !this.card._complete;

      this.paymentRequest?.update(this.paymentRequestOptions);

      this.amountWarningTarget.hidden = parseFloat(this.amount) < 250;
    } else {
      this.error(message || unknownError);
    }
  }

  confirmAmount(e) {
    e.preventDefault();
    this.amountWarningTarget.hidden = true;
  }

  async pay(e) {
    e.preventDefault();

    this.disabled = true;

    const { error, paymentIntent } = await this.stripe.confirmCardPayment(this.clientSecret, {
      payment_method: {
        card: this.card
      }
    })

    if(error) {
      this.error(error.message);
      return false;
    }

    this.verifyPayment(paymentIntent);
  }

  async verifyPayment(paymentIntent) {
    const data = await fetch("/.netlify/functions/payment-transaction", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await this.jwt()}`
      },
      body: JSON.stringify({
        id: paymentIntent.id,
        clientSecret: this.clientSecret
      })
    }).then(res => res.ok ? res.json() : { success: false, message: unknownError });

    console.log("Response", data);
    if(data.success) {
      await this.success();
    } else {
      this.disabled = false;
      this.error(data.message || unknownError);
    }
  }

  async success() {
    // Refresh user data to get new roles
    await identity.currentUser().getUserData();

    console.log("Refreshed user", identity.currentUser())
    window.location = this.element.action;
  }

  error(message) {
    if(message) {
      this.errorTarget.innerText = message;
      this.errorTarget.hidden = false;
    } else {
      this.errorTarget.hidden = true;
    }
  }

  loading() {
    this.element.classList.add('loading');
  }

  loaded() {
    this.element.classList.remove('loading');
  }

  get paymentRequestOptions() {
    return {
      currency: 'usd',
      total: {
        label: '80º North Series',
        amount: this.amount * 100,
      }
    }
  }



  // Get JWT for current user
  async jwt(force = false) {
    return identity.currentUser().jwt(force);
  }

  set disabled(bool) {
    this.buttonTarget.disabled = bool;
  }

  get amount() {
    return this.amountTarget.value;
  }
}
