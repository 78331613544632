import { Controller } from "stimulus";
import identity from "../identity";
import * as Sentry from '@sentry/browser';

export default class extends Controller {
  static targets = ["password", "error", "button"];

  async initialize() {
    const token = window.location.hash.match(/^#recovery_token=(.*)$/)?.pop();

    if(token) {
      try {
        await identity.recover(token);
      } catch (error) {
        await Sentry.captureException(error);
      }
    }

    if(!identity.currentUser()) {
      window.location = "/login"
    }
  }

  async submit(e) {
    e.preventDefault();

    this.buttonTarget.disabled = true;
    this.errorTarget.hidden = true;
    this.element.classList.remove('uk-animation-shake');

    try {
      await identity.currentUser().update({password: this.passwordTarget.value});
      window.location = this.element.action;
    } catch (error) {
      this.buttonTarget.disabled = false;
      this.error(error)
    }
  }

  error(error) {
    this.errorTarget.textContent = error?.json.error_description || error.message || error.toString();
    this.errorTarget.hidden = false;
    this.element.classList.add('uk-animation-shake');
  }
}
